/**
 * FileUploader
 * Copyleft (c) 2017 Innostudio.de
 * Website: http://innostudio.de/fileuploader/
 * Version: 1.3 (21-Sep-2017)
 * Requires: jQuery v1.7.1 or later
 * License: https://innostudio.de/fileuploader/documentation/#license
 */


/* 
    Configuration
*/

.fileuploader,
.fileuploader *,
.fileuploader :before,
.fileuploader :after {
    box-sizing: border-box;
}


/* 
    Parent
*/

.fileuploader {
    display: block;
    width: 100%;
    padding: 15px;
    margin: 15px 0;
    background: #f5f6fA;
    border-radius: 4px;
    line-height: normal;
    text-align: right;
}


/* 
    Input
*/

.fileuploader-input {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 1px solid transparent;
    cursor: pointer;
    overflow: hidden;
}


/* input caption */

.fileuploader-input-caption {
    position: relative;
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -ms-flex-item-align: start;
    align-self: flex-start;
    padding: 11px 15px;
    margin-left: 15px;
    background: #fff;
    border: 1px solid #dde4f6;
    border-radius: 4px;
    color: #789BEC;
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}


/* input button */

.fileuploader-input-button {
    display: inline-block;
    background: #6381E6;
    padding: 12px 20px;
    border-radius: 4px;
    color: #fff;
    text-align: center;
    font-weight: bold;
    vertical-align: top;
    cursor: pointer;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.fileuploader-input-button:hover {
    background: #758fe9;
}

.fileuploader-input-button:active {
    background: #6381E6;
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
}


/* input focused state */

.fileuploader-focused .fileuploader-input-caption {
    border-color: #b8c2ef;
}

.fileuploader-focused .fileuploader-input-button {
    background: #758fe9;
    border-color: #758fe9;
}


/* input disabled state */

.fileuploader-disabled .fileuploader-input {
    opacity: 0.7;
    cursor: default;
    pointer-events: none !important;
}


/* input dragging state */

.fileuploader-theme-default .fileuploader-dragging {
    background: #fff;
    border: 1px dashed #dde4f6;
}

.fileuploader-theme-default .fileuploader-dragging .fileuploader-input-caption {
    border-color: transparent;
}

.fileuploader-theme-default .fileuploader-dragging .fileuploader-input-button {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    opacity: 0;
}


/* input uploading state */

.fileuploader.fileuploader-is-uploading .fileuploader-input-caption:after {
    width: 14px;
    height: 14px;
    left: 10px;
    top: 50%;
    margin-top: -10px;
    opacity: 0.8;
}


/* global loader */

.fileuploader.fileuploader-is-uploading .fileuploader-input-caption:after,
.fileuploader-item .fileuploader-item-image.fileuploader-loading:after,
.fileuploader-item.upload-pending .fileuploader-action-remove:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border-top: 3px solid #dde4f6;
    border-left: 3px solid #dde4f6;
    border-bottom: 3px solid #dde4f6;
    border-right: 3px solid #979fb8;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: fileuploaderSpin 1s infinite linear;
    animation: fileuploaderSpin 1s infinite linear;
}


/*
    Thumbnails
    default theme
*/


/* list */

.fileuploader-items-list {
    display: block;
    margin: 0 -15px;
    padding: 0;
    list-style: none;
}


/* item */

.fileuploader-item {
    position: relative;
    margin: 0;
    padding: 20px 15px;
    border-bottom: 1px solid #e8e8e8;
    -webkit-animation: fileuploaderSlideIn 0.4s ease;
    animation: fileuploaderSlideIn 0.4s ease;
}

.fileuploader-item:last-child {
    border-bottom: 0;
    margin-bottom: -15px;
}


/* item uploading state */

.fileuploader-item.upload-failed {
    background: rgba(219, 104, 104, 0.08);
}

.fileuploader-item.upload-pending .fileuploader-action-remove:after {
    width: 26px;
    height: 26px;
    left: -5px;
    top: 50%;
    margin-top: -13px;
    opacity: 0.2;
}


/* item columns */

.fileuploader-item .columns {
    position: relative;
    z-index: 2;
}


/* item column-thumbnail */

.fileuploader-item .column-thumbnail {
    position: absolute;
    width: 36px;
    height: 36px;
    line-height: 36px;
    right: 0;
    top: 0;
}

.fileuploader-item .column-thumbnail .fileuploader-item-image {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
}

.fileuploader-item .fileuploader-item-image img {
    max-width: none;
    max-height: 100%;
    min-height: 100%;
}

.fileuploader-item .fileuploader-item-image img,
.fileuploader-item .fileuploader-item-image canvas {
    position: absolute;
    top: 50%;
    right: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    -webkit-animation: fileuploaderFadeIn .2s ease;
    animation: fileuploaderFadeIn .2s ease;
}


/* thumbnail loading state */

.fileuploader-item .column-thumbnail .fileuploader-item-image.fileuploader-loading:after {
    width: 24px;
    height: 24px;
    right: 50%;
    top: 50%;
    margin-right: -12px;
    margin-top: -12px;
    border-radius: 50%;
    opacity: 0.12;
}


/* thumbnail popup button */

.fileuploader-item .column-thumbnail .fileuploader-action-popup {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.fileuploader-item.file-has-popup .column-thumbnail .fileuploader-action-popup {
    display: block;
}

.fileuploader-item .column-thumbnail .fileuploader-action-popup:hover {
    opacity: 1;
}

.fileuploader-item .column-thumbnail .fileuploader-action-popup:active {
    background: rgba(0, 0, 0, 0.4);
}

.fileuploader-item.file-type-video .column-thumbnail .fileuploader-action-popup:after,
.fileuploader-item.file-type-audio .column-thumbnail .fileuploader-action-popup:after {
    content: '';
    position: absolute;
    right: 50%;
    top: 50%;
    margin-right: -4px;
    margin-top: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid rgba(0, 0, 0, 0);
    border-bottom: 8px solid rgba(0, 0, 0, 0);
    border-right: 10px solid #fff;
}


/* item icon */

.fileuploader-item .fileuploader-item-icon {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    color: #fff;
    font-size: 11px;
    background: #ddd;
    border-radius: 4px;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-animation: fileuploaderFadeIn .2s ease;
    animation: fileuploaderFadeIn .2s ease;
}

.fileuploader-item .fileuploader-item-icon.is-bleft-color {
    color: #888;
}

.fileuploader-item .fileuploader-item-icon i {
    display: block;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: bold;
}


/* item icon like file */


/*
.fileuploader-item .fileuploader-item-icon {
    width: 30px;
    margin: 0 auto;
    border-radius: 3px;
}
.fileuploader-item .fileuploader-item-icon i {
    padding-top: 3px;
}
.fileuploader-item .fileuploader-item-icon:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    border-bottom-right-radius: 2px;
    border-width: 4px;
    border-style: solid;
    border-color: #f5f6fa #f5f6fa rgba(255, 255, 255, .35) rgba(255, 255, 255, .35);
}
.fileuploader-item .fileuploader-item-icon {
    -webkit-animation: fileuploaderFadeIn 0.4s ease;
    		animation: fileuploaderFadeIn 0.4s ease;
}
*/


/* item column-title */

.fileuploader-item .column-title {
    display: block;
    padding-right: 51px;
    padding-left: 81px;
    color: #74809D;
}

.fileuploader-item a {
    color: #74809D;
    text-decoration: none;
}

.fileuploader-item .column-title div {
    width: 100%;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fileuploader-item .column-title span {
    font-size: 12px;
    color: #979fb8;
}


/* item actions */

.fileuploader-item .column-actions {
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -9px;
}

.fileuploader-action {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    cursor: pointer;
    vertical-align: top;
    -webkit-transform: translateZ(0px);
    transform: translateZ(0px);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}

.fileuploader-item .column-actions .fileuploader-action+.fileuploader-action {
    margin-right: 10px;
}

.fileuploader-item .column-actions .fileuploader-action-success {
    position: absolute;
    left: 0;
    top: 0;
}

.fileuploader-item .column-actions .fileuploader-action-success:hover,
.fileuploader-item .column-actions .fileuploader-action-success:active {
    opacity: 0;
}


/* icon.remove */

.fileuploader-action-remove {
    background: #fae1e1;
    border: 1px solid #ffcfcf;
}

.fileuploader-action-remove:hover {
    background: #ffefef;
}

.fileuploader-action-remove:active {
    background: #ffcfcf;
}

.fileuploader-action-remove i:before,
.fileuploader-action-remove i:after {
    content: '';
    position: absolute;
    top: 4px;
    right: 7px;
    height: 8px;
    width: 2px;
    background-color: #f59595;
}

.fileuploader-action-remove i:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.fileuploader-action-remove i:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}


/* icon-start */

.fileuploader-action-start {
    background: #6381E6;
    border-radius: 50%;
}

.fileuploader-action-start i {
    position: absolute;
    right: 50%;
    top: 50%;
    margin-right: -2px;
    margin-top: -5px;
    border-radius: 2px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-right: 5px solid #fff;
}

.fileuploader-action-start:hover {
    background: #758fe9;
}

.fileuploader-action-start:active {
    background: #6381E6;
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
}


/* icon.success */

.fileuploader-action-success {
    background: #6381E6;
    border: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.fileuploader-action-success i:before,
.fileuploader-action-success i:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(0);
    transform: rotate(0);
}

.fileuploader-action-success i:before {
    width: 4px;
    height: 2px;
    right: 6px;
    top: 11px;
    background: #fff;
}

.fileuploader-action-success i:after {
    top: 4px;
    right: 9px;
    width: 2px;
    height: 9px;
    background: #fff;
}


/* icon-download */

.fileuploader-action-download {
    background: #6381E6;
    border-radius: 50%;
}

.fileuploader-action-download i {
    position: absolute;
    right: 50%;
    top: 50%;
    margin-right: -1px;
    margin-top: -4px;
    width: 2px;
    height: 5px;
    border-radius: 0;
    background: #fff;
}

.fileuploader-action-download i:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: -4px;
    right: -3px;
    border-right: 4px solid rgba(0, 0, 0, 0);
    border-left: 4px solid rgba(0, 0, 0, 0);
    border-top: 5px solid #fff;
    border-radius: 2px;
}

.fileuploader-action-download:hover {
    background: #758fe9;
}

.fileuploader-action-download:active {
    background: #6381E6;
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
}


/* icon-retry */

.fileuploader-action-retry {
    background: #6381E6;
    border-radius: 50%;
}

.fileuploader-action-retry i:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    right: 4px;
    top: 4px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.fileuploader-action-retry i:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 3px;
    right: 3px;
    top: 9px;
    background: #6381E6;
    -webkit-transform: rotate(-35deg);
    transform: rotate(-35deg);
}

.fileuploader-action-retry:hover,
.fileuploader-action-retry:hover i:after {
    background: #758fe9;
}

.fileuploader-action-retry:active {
    box-shadow: inset 0 1px 6px rgba(0, 0, 0, 0.1);
}

.fileuploader-action-retry:active,
.fileuploader-action-retry:active i:after {
    background: #6381E6;
}


/* icon-sort */

.fileuploader-action-sort {
    background: #b0b8d0;
    text-align: center;
    cursor: move;
}

.fileuploader-action-sort:hover,
.fileuploader-action-sort:active {
    background: #979fb8;
}

.fileuploader-action-sort i {
    position: relative;
    display: inline-block;
    top: 8px;
    width: 8px;
    height: 2px;
    background: #fff;
    vertical-align: top;
}

.fileuploader-action-sort i:before {
    content: '';
    position: absolute;
    display: inline-block;
    background: #fff;
    width: 100%;
    height: 2px;
    top: -3px;
    right: 0;
}

.fileuploader-action-sort i:after {
    content: '';
    position: absolute;
    display: inline-block;
    background: #fff;
    width: 100%;
    height: 2px;
    top: 3px;
    right: 0;
}


/* item progress bar */

.fileuploader-item .fileuploader-progressbar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 4px;
    border-radius: 4px;
    overflow: hidden;
}

.fileuploader-item .fileuploader-progressbar .bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 0%;
    height: 100%;
    border-radius: 4px;
    background: #6381E6;
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

.fileuploader-item .progress-bar2 .fileuploader-progressbar {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fileuploader-item .progress-bar2 .fileuploader-progressbar .bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 0%;
    height: 100%;
    border-radius: 0;
    background: rgba(104, 125, 219, 0.08);
    -webkit-transition: width 0.3s ease;
    transition: width 0.3s ease;
}

.fileuploader-item .progress-bar2 span {
    position: absolute;
    top: 50%;
    margin-top: -29px;
    left: 15px;
    color: rgba(151, 159, 184, 0.16);
    font-size: 48px;
}


/* clipboard paste loading */

.fileuploader-input-caption .fileuploader-pending-loader {
    margin-left: 5px;
}

.fileuploader-pending-loader {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    overflow: hidden;
    vertical-align: middle;
}

.fileuploader-pending-loader:after,
.fileuploader-pending-loader .right-half {
    background: #fff;
}

.fileuploader-pending-loader .left-half,
.fileuploader-pending-loader .spinner {
    background: #789BEC;
}

.fileuploader-pending-loader:after {
    content: '';
    position: absolute;
    top: 3px;
    right: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    z-index: 4;
}

.fileuploader-pending-loader .right-half,
.fileuploader-pending-loader .left-half,
.fileuploader-pending-loader .spinner {
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
}

.fileuploader-pending-loader .right-half {
    right: 0;
    opacity: 1;
    border-radius: 100% 0 0 100%/ 50% 0 0 50%;
    -webkit-animation: fileuploaderOpacity 3s steps(1, end) infinite;
    animation: fileuploaderOpacity 3s steps(1, end) infinite;
    z-index: 3;
}

.fileuploader-pending-loader .left-half {
    left: 0;
    opacity: 0;
    border-radius: 0 100% 100% 0/ 0 50% 50% 0;
    -webkit-animation: fileuploaderOpacity 3s steps(1, end) reverse infinite;
    animation: fileuploaderOpacity 3s steps(1, end) reverse infinite;
    z-index: 1;
}

.fileuploader-pending-loader .spinner {
    right: 0;
    border-radius: 100% 0 0 100%/ 50% 0 0 50%;
    -webkit-animation: fileuploaderSpin 3s linear infinite;
    animation: fileuploaderSpin 3s linear infinite;
    -webkit-transform-origin: center left;
    transform-origin: center left;
    z-index: 2;
}


/* fileuploader popup */

.fileuploader-popup {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgba(40, 46, 61, 0.9);
    z-index: 90;
    -webkit-animation: fileuploaderFadeIn 0.4s ease;
    animation: fileuploaderFadeIn 0.4s ease;
}

.fileuploader-popup-preview {
    width: 100%;
    height: 100%;
    padding: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    z-index: 2;
}

.fileuploader-popup-preview .node {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 10px;
    text-align: center;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: fileuploaderSlideInDown 0.4s;
    animation: fileuploaderSlideInDown 0.4s;
}

.fileuploader-popup-preview .node.image>img,
.fileuploader-popup-preview .node.video>video,
.fileuploader-popup-preview .node.audio>audio,
.fileuploader-popup-preview .node.astext>div {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    padding: 0;
    color: #47525d;
    background: #fff;
    -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
}

.fileuploader-popup-preview .node.audio audio {
    width: 450px;
}

.fileuploader-popup-preview .node.astext div {
    max-width: 992px;
    padding: 20px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 16px;
    text-align: right;
    overflow-y: auto;
    white-space: pre-wrap;
}

.fileuploader-popup-preview .tools {
    width: 100%;
    margin-top: 30px;
}

.fileuploader-popup-preview .tools:after {
    content: '';
    position: absolute;
    right: 0;
    width: 100%;
    margin-top: -240px;
    height: 2000px;
    background: rgba(35, 40, 54, 0.96);
    -webkit-box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.4);
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.4);
    z-index: -1;
}

.fileuploader-popup-preview .tools ul {
    display: table;
    list-style: none;
    margin: 0 auto;
    padding: 0;
    -webkit-animation: fileuploaderSlideIn 0.4s;
    animation: fileuploaderSlideIn 0.4s;
}

.fileuploader-popup-preview .tools li {
    display: inline-block;
    padding: 0 20px;
    max-width: 50%;
    vertical-align: middle;
}

.fileuploader-popup-preview .tools li span {
    display: block;
    color: #868896;
    margin-top: 3px;
    font-size: 14px;
}

.fileuploader-popup-preview .tools li h5 {
    margin: 8px 0;
    color: #dfdeee;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileuploader-popup-preview .tools li a {
    display: inline-block;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    -webkit-transition: opacity 0.2s ease;
    transition: opacity 0.2s ease;
}

.fileuploader-popup-preview .tools li a:hover {
    opacity: 0.6;
}

.fileuploader-popup-preview .tools li a i {
    display: inline-block;
    width: 23px;
    height: 23px;
    margin-bottom: 2px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.fileuploader-popup-preview .tools li a[data-action="crop"] i {
    background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xNiAwLjd2LTAuN2gtMC43bC0zIDNoLTcuM3YtM2gtMnYzaC0zdjJoM3Y4aDh2M2gydi0zaDN2LTJoLTN2LTcuM2wzLTN6TTUgNWg1LjNsLTUuMyA1LjN2LTUuM3pNMTEgMTFoLTUuM2w1LjMtNS4zdjUuM3oiLz4KPC9zdmc+Cg==');
}

.fileuploader-popup-preview .tools li a[data-action="rotate-cw"] i {
    background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0xNiA3di00bC0xLjEgMS4xYy0xLjMtMi41LTMuOS00LjEtNi45LTQuMS00LjQgMC04IDMuNi04IDhzMy42IDggOCA4YzIuNCAwIDQuNi0xLjEgNi0yLjhsLTEuNS0xLjNjLTEuMSAxLjMtMi43IDIuMS00LjUgMi4xLTMuMyAwLTYtMi43LTYtNnMyLjctNiA2LTZjMi40IDAgNC41IDEuNSA1LjUgMy41bC0xLjUgMS41aDR6Ii8+Cjwvc3ZnPgo=');
}

.fileuploader-popup-preview .tools li a[data-action="remove"] i {
    background-image: url('data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHdpZHRoPSIyNHB4IiBoZWlnaHQ9IjI0cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiI+CjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yIDV2MWgxdjljMCAwIDEuMSAxIDQuNSAxczQuNS0xIDQuNS0xdi05aDF2LTFoLTExek02IDE0aC0xdi03aDF2N3pNOCAxNGgtMXYtN2gxdjd6TTEwIDE0aC0xdi03aDF2N3oiLz4KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTEyIDNjMCAwIDAtMC41LTItMC44di0wLjdjMC0wLjgtMC43LTEuNS0xLjUtMS41aC0yYy0wLjggMC0xLjUgMC43LTEuNSAxLjV2MC43Yy0xLjYgMC4zLTIgMC44LTIgMC44aC0xdjFoMTF2LTFoLTF6TTYgMS41YzAtMC4zIDAuMi0wLjUgMC41LTAuNWgyYzAuMiAwIDAuNSAwLjIgMC41IDAuNXYwLjZjMC0wLjEtMC45LTAuMS0xLjUtMC4xcy0xLjEgMC0xLjUgMC4xdi0wLjZ6Ii8+Cjwvc3ZnPgo=');
}

.fileuploader-popup-preview .tools li.separator {
    position: relative;
    margin: 5px 40px 5px 0px;
    height: 40px;
    border-left: 1px solid #36394e;
}

.fileuploader-popup-preview .tools .buttons {
    text-align: center;
    margin-top: 30px;
    -webkit-animation: fileuploaderSlideIn 0.4s;
    animation: fileuploaderSlideIn 0.4s;
}

.fileuploader-popup-button {
    display: inline-block;
    background: transparent;
    color: #596671;
    font-weight: bold;
    border-radius: 4px;
    padding: 11px 20px;
    margin: 0 10px;
    cursor: pointer;
    border: none;
    outline: 0;
    border: 2px solid #596671;
    text-decoration: none;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
}

.fileuploader-popup-button:hover {
    background: #e6ebf4;
    border-color: #e6ebf4;
    color: #90a0bc;
    text-decoration: none;
}

.fileuploader-popup-button:focus,
.fileuploader-popup-button:active {
    color: #90a0bc;
    background: #d1d9e8;
    border-color: #d1d9e8;
    text-decoration: none;
}

.fileuploader-popup-button.button-success {
    color: #fff;
    background: #6381E6;
    border-color: #6381E6;
}

.fileuploader-popup-button.button-success:hover {
    background: #758fe9;
    border-color: #758fe9;
    color: #fff;
    text-decoration: none;
}

.fileuploader-popup-button.button-success:focus,
.fileuploader-popup-button.button-success:active {
    color: #fff;
    background: #6381E6;
    border-color: #6381E6;
}

.fileuploader-cropper,
.fileuploader-cropper * {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.fileuploader-cropper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 20, 27, .65);
    z-index: 9;
}

.fileuploader-cropper-area {
    position: absolute;
    right: 0;
    top: 0;
    width: 0;
    height: 0;
    z-index: 2;
}

.fileuploader-cropper-area.has-grid:before,
.fileuploader-cropper-area.has-grid:after {
    content: '';
    position: absolute;
    border: 1px solid rgba(250, 250, 250, 0.8);
    opacity: 0;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
    z-index: 1;
}

.fileuploader-cropper-area.has-grid:before {
    top: 0;
    right: 50%;
    height: 100%;
    width: 34%;
    border-top: 0;
    border-bottom: 0;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.fileuploader-cropper-area.has-grid:after {
    top: 50%;
    right: 0;
    height: 34%;
    width: 100%;
    border-right: 0;
    border-left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.fileuploader-cropper-area.has-grid.moving:before,
.fileuploader-cropper-area.has-grid.moving:after {
    opacity: 1;
}

.fileuploader-cropper-area .point {
    position: absolute;
    width: 12px;
    height: 12px;
    background: #fafafa;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    z-index: 3;
}

.fileuploader-cropper-area .point-a {
    top: -6px;
    right: -6px;
    cursor: nw-resize;
}

.fileuploader-cropper-area .point-b {
    top: -6px;
    right: 50%;
    margin-right: -6px;
    cursor: n-resize;
}

.fileuploader-cropper-area .point-c {
    top: -6px;
    left: -6px;
    cursor: ne-resize;
}

.fileuploader-cropper-area .point-d {
    top: 50%;
    left: -6px;
    margin-top: -6px;
    cursor: w-resize;
}

.fileuploader-cropper-area .point-e {
    bottom: -6px;
    left: -6px;
    cursor: nw-resize;
}

.fileuploader-cropper-area .point-f {
    bottom: -6px;
    right: 50%;
    margin-right: -6px;
    cursor: s-resize;
}

.fileuploader-cropper-area .point-g {
    bottom: -6px;
    right: -6px;
    cursor: sw-resize;
}

.fileuploader-cropper-area .point-h {
    right: -6px;
    top: 50%;
    margin-top: -6px;
    cursor: w-resize;
}

.fileuploader-cropper-area .area-move {
    position: absolute;
    width: 95%;
    height: 95%;
    right: 50%;
    top: 50%;
    z-index: 2;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    cursor: move;
}

.fileuploader-cropper-area .area-image {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.fileuploader-cropper-area .area-image img {
    width: auto;
    height: auto;
    max-width: none;
    max-height: none;
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    -webkit-transform-origin: top right;
    transform-origin: top right;
}


/* fileuploader-sorter */

.fileuploader-item.sorting {
    background: #f5f6fA;
    border-radius: 4px;
    opacity: 0.9;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    z-index: 799;
}

.fileuploader-item.sorting,
.fileuploader-item.sorting .fileuploader-item-image img,
.fileuploader-item.sorting .fileuploader-item-image canvas,
.fileuploader-item.sorting .fileuploader-item-icon,
.fileuploader-sorter-placeholder {
    -webkit-animation: none;
    animation: none;
}

.fileuploader-sorter-placeholder {
    background: rgba(0, 0, 0, 0.03);
    margin: 0;
    padding: 0;
}


/* animations */

@-webkit-keyframes fileuploaderOpacity {
    0% {
        opacity: 1;
    }
    50%,
    100% {
        opacity: 0;
    }
}

@keyframes fileuploaderOpacity {
    0% {
        opacity: 1;
    }
    50%,
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes fileuploaderFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fileuploaderFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fileuploaderScaleIn {
    from {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes fileuploaderScaleIn {
    from {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    to {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes fileuploaderSlideIn {
    from {
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes fileuploaderSlideIn {
    from {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
    }
}

@-webkit-keyframes fileuploaderSlideOut {
    from {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
    }
    to {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        opacity: 0;
    }
}

@keyframes fileuploaderSlideOut {
    from {
        -webkit-transform: translateY(0%);
        transform: translateY(0%);
        opacity: 1;
    }
    to {
        -webkit-transform: translateY(50%);
        transform: translateY(50%);
        opacity: 0;
    }
}

@-webkit-keyframes fileuploaderSlideInDown {
    from {
        -webkit-transform: translateY(-5%);
        transform: translateY(-5%);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fileuploaderSlideInDown {
    from {
        -webkit-transform: translateY(-5%);
        transform: translateY(-5%);
        opacity: 0;
    }
    to {
        -webkit-transform: translateY(0);
        transform: translateY(0);
        opacity: 1;
    }
}

@-webkit-keyframes fileuploaderSpin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes fileuploaderSpin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}